<template>
  <div>
    <div>
      <v-container
        fluid
      >
        <div class="text-center">
          <v-progress-circular
            v-if="loadingProgramFile"
            :size="40"
            color="primary"
            indeterminate
          />
          <v-card-text
            v-if="!loadingProgramFile && !loadedFromServer"
            class="subtitle-1"
          >
            {{ '$l.program.programNotSaved' | translate }}
          </v-card-text>
          <v-card-text
            v-if="!loadingProgramFile && loadedFromServer"
            class="subtitle-1"
          >
            {{ $i18n.t('$l.program.programUploadedDate') + ' ' }}<span style="white-space: nowrap">{{ programUploadedDate }}</span>
          </v-card-text>

          <v-btn
            v-if="!loadingProgramFile && loadedFromServer"
            class="info"
            text
            @click="showUpload()"
          >
            {{ $i18n.t('$l.program.updateFile') }}
          </v-btn>
        </div>
      </v-container>
    </div>
    <div v-if="!loadingProgramFile && !loadedFromServer">
      <v-container
        fluid
        class="uploader mt-8 pa-3"
        :class="{ dragging: isDragging }"
        @dragenter="OnDragEnter"
        @dragleave="OnDragLeave"
        @dragover.prevent
        @drop="onDrop"
      >
        <app-api-call
          class="mb-3"
          :call-result="callResult"
        />

        <v-file-input
          v-model="selectedFile"
          color="success"
          class="subtitle-2"
          :placeholder="$i18n.t('$l.program.selectYourFile')"
          prepend-icon="mdi-paperclip"
          outlined
          @change="onInputChange"
        >
          <template v-slot:selection="{ text }">
            <v-chip
              color="success"
              dark
              label
              small
            >
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
        <v-row>
          <v-card-text
            class="subtitle-2"
          >
            <v-icon
              large
              class="mr-5"
            >
              mdi-cloud-upload-outline
            </v-icon>
            {{ '$l.program.orMoveFileHere' | translate }}
          </v-card-text>
        </v-row>
      </v-container>

      <v-container
        fluid
      >
        <v-row>
          <v-spacer />
          <v-btn
            :disabled="pdfSrcData === null"
            class="primary"
            @click="upload"
          >
            {{ $i18n.t('$l.app.confirm') }}
          </v-btn>
        </v-row>
      </v-container>
    </div>

    <pdf
      v-for="i in numPages"
      :key="i"
      :src="pdfSrcData"
      :page="i"
      style="display: inline-block; width: 100%"
    />
  </div>
</template>

<script>
import pdf from 'vue-pdf'

const readFile = (inputFile) => {
  const fileReader = new FileReader()
  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort()
      reject(new Error('Problem parsing input file.'))
    }
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.readAsDataURL(inputFile)
  })
}

export default {
  name: 'FileUploader',
  components: {
    pdf
  },
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      selectedFile: null,
      isDragging: false,
      dragCount: 0,
      pdfSrcData: null,
      numPages: '',
      loadedFromServer: false,
      loadingProgramFile: true,
      programUploadedDate: '',
      lastModified: 0
    }
  },
  created () {
    this.loadProgramFile()
  },
  methods: {
    loadProgramFile () {
      this.callResult.error = null
      this.$xapi.get('endpointsFile/programfile', { responseType: 'blob' })
        .then(r => {
          if (r.status === 204) {
            this.loadedFromServer = false
            this.loadingProgramFile = false
            return
          }
          readFile(r.data)
            .then(fd => {
              if (fd.length > 5) {
                this.pdfSrcData = pdf.createLoadingTask(fd)
                this.pdfSrcData.promise.then(pdf => {
                  this.numPages = pdf.numPages
                  this.loadedFromServer = true
                  this.loadingProgramFile = false
                })
                this.$xapi.get('endpointsFile/programfile/uploaded')
                  .then(r => {
                    this.programUploadedDate = this.$utils.formatDateWithoutTime(r.data.replace('[UTC]', ''))
                  }).catch(e => {
                    this.callResult.error = e.response.localizedMessage
                    this.loadingProgramFile = false
                  })
              }
            })
            .catch(e => {
              this.callResult.error = e.response.localizedMessage
              this.loadingProgramFile = false
            })
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
          this.loadingProgramFile = false
        })
    },
    onInputChange (e) {
      if (e !== null) {
        this.checkAndLoadFile(e)
      }
    },
    OnDragEnter (e) {
      e.preventDefault()
      this.dragCount++
      this.isDragging = true
      return false
    },
    OnDragLeave (e) {
      e.preventDefault()
      this.dragCount--
      if (this.dragCount <= 0) { this.isDragging = false }
    },
    onDrop (e) {
      this.callResult.error = null
      e.preventDefault()
      e.stopPropagation()
      this.isDragging = false
      const files = e.dataTransfer.files
      if (files.length === 1) {
        this.selectedFile = files[0]
        this.checkAndLoadFile(this.selectedFile)
        this.callResult.error = null
      } else {
        this.callResult.error = this.$i18n.t('$l.program.notValidTypeOfFile')
      }
    },
    checkAndLoadFile (file) {
      this.callResult.error = null
      if ((file && file.type && !file.type.match('pdf.*'))) {
        this.callResult.error = this.$i18n.t('$l.program.notValidTypeOfFile')
        this.$nextTick(() => {
          this.selectedFile = null
        })
        return
      }
      if (file) {
        readFile(file)
          .then(fd => {
            this.pdfSrcData = pdf.createLoadingTask(fd)
            this.pdfSrcData.promise.then(pdf => {
              this.numPages = pdf.numPages
            })
          })
      } else {
        this.pdfSrcData = null
      }
    },
    upload () {
      this.callResult.error = null
      let formData = new FormData()
      formData.append('byteData', this.selectedFile)
      if (this.selectedFile.name) {
        formData.append('fileName', this.selectedFile.name)
      }
      this.$xapi.post('endpointsFile/programfile', formData)
        .then(() => {
          this.selectedFile = null
          this.loadProgramFile()
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
    },
    showUpload () {
      this.loadedFromServer = false
      this.pdfSrcData = null
      this.numPages = 0
    }
  }

}
</script>

<style lang="scss" scoped>
  .uploader {
    width: 100%;
    height: 14em;
    text-align: center;
    border: 3px dashed $greycc;
    &.dragging {
      background: #fff;
      border: 3px dashed $success;
      .file-input label {
          background: $success;
      }
    }
  }
</style>
