<template>
  <ProgramWrapper />
</template>

<script>
import ProgramWrapper from '@/components/program/ProgramWrapper'

export default {
  name: 'Program',
  components: {
    ProgramWrapper
  }
}
</script>
