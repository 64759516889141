<template>
  <v-container
    fluid
  >
    <basic-note-hint class="mt-4">
      {{ $i18n.t('$l.hint.programPage') }}
    </basic-note-hint>

    <basic-card
      title="$l.paths.program"
      class="mt-12"
    >
      <app-api-call
        class="ma-0 pa-0 mb-3"
        :call-result="callResult"
      />
      <FileUploader
        v-if="callResult.finished && callResult.authorized"
      />
      <basic-service-required
        v-else
        title="$l.investigation.programPackageRequired"
      />
    </basic-card>
  </v-container>
</template>

<script>
import FileUploader from './FileUploader.vue'
export default {
  name: 'ProgramWrapper',
  components: {
    FileUploader
  },
  data () {
    return {
      callResult: { finished: true, authorized: true, error: null, info: null }
    }
  }
}
</script>
